import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'unstated';
import Routes from './routes';
import history from './history'

import './App.scss';

class App extends Component {
  
  render() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    return (
      <Provider>
        <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>
          <div className="App">
            <Routes history={history} />
            {/* <div className="App-landscape-block">Ruota il dispositivo!</div> */}
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;