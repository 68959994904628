import React, { Component } from 'react';
import CountUp from 'react-countup';
import { isMobile } from "react-device-detect";

import './AccusedItem.scss';

class AccusedItem extends Component {

    state = {
        height: 0
    }

    componentDidMount() {
        this.calcHeight = setTimeout(() => {
            this.setState({
                height: this.props.number * 100 / this.props.total
            })
        }, 200);
    }

    componentWillUnmount() {
        clearTimeout(this.calcHeight);
    }
  
    render() {
        const { name, number, total } = this.props;
        const { height } = this.state;

        return (
            <div className={`AccusedItem AccusedItem-${name}`}>
                <div className="AccusedItem-img">
                    <div className="AccusedItem-state">
                        <div className="AccusedItem-state-value">
                            <CountUp delay={0.2} end={number * 100 / total} decimals={2} suffix="%" />
                        </div>
                        { isMobile
                        ?
                            <div
                                className="AccusedItem-state-placeholder"
                                style={{width: `${height}%`}} />
                        :
                            <div
                                className="AccusedItem-state-placeholder"
                                style={{height: `${height}%`}} />
                        }
                    </div>
                </div>
                <h3 className="AccusedItem-name">{ name }</h3>
            </div>
        );
    }
}

export default AccusedItem;