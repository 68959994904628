import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import { ROUTES } from '../routes';
import Logo from './Logo/Logo';
import Loader from './Loader/Loader';
import Sharing from './Sharing/Sharing';
import videoWin from '../assets/corretto.mp4';
import videoLose from '../assets/sbagliato.mp4';
import * as ReactGA from '../GA';

import './Results.scss';

const BASE_URL = process.env.REACT_APP_BASE_URL;

class SolveTheCase extends Component {

    state = {
        sended: false,
        guilty: ''
    }

    componentDidMount() {

        // check reference to know if the user as already send his accusation
        const accuseSended = localStorage.getItem('n_accuse_sended');
        const accuseGuilty = localStorage.getItem('n_accuse_guilty');

        if(accuseSended !== null) {

            this.props.AppStore.getAccusations(accuseGuilty);

            this.setState({
                sended: true
            }, () => {
                ReactGA.pageview(`/risultato`);
            });
        }
    }
  
    render() {

        const { AppStore } = this.props;

        const win = AppStore.state.accusation.guilty === 'Olivia' ? true : false;

        const resultsTpl = (
            <div className="Results-result">
                <div className="Results-result-content">
                    <h1 className="Results-title Results-result-title">
                        { win ? 'Risposta esatta!' : 'Risposta sbagliata!'}
                    </h1>
                    { win ?
                        <Fragment>
                            <div className="Results-result-actions Results-result-actions--win">
                                <Link to={ROUTES.SOLUTION} className="Results-accuse">
                                    Guarda la soluzione
                                </Link>
                            </div>
                            <div className="SolveTheCase-share">
                                <Sharing
                                    sharingTitle="Condividi"
                                    small={true}
                                    url={`${BASE_URL}`}
                                    title={`Io l'ho risolto, e tu?`} />
                            </div>
                        </Fragment>
                        :
                        <div className="Results-result-actions Results-result-actions--lose">
                            <p>Sei sicuro di non esserti perso qualcosa?</p>
                            <button onClick={() => AppStore.emptyAccuse()} className="Results-accuse">
                                Torna all'indagine
                            </button>
                            <Link to={ROUTES.SOLUTION} className="Results-accuse">
                                Mi arrendo
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )

        return (
            <article className={`Results`}>
                <Logo to={ROUTES.INVESTIGATED} />
                <div className={`Page`}>

                    {/* is loading */}
                    { AppStore.state.loading &&
                        <Loader />
                    }

                    {/* is not loading and accusations sent */}
                    {
                        !AppStore.state.loading && (AppStore.state.accusation.sended || this.state.sended) &&
                        resultsTpl
                    }

                    {/* is not loading and accusations not sent */}
                    {
                        !AppStore.state.loading && !AppStore.state.accusation.sended &&
                        <Redirect to={ROUTES.INVESTIGATED} />
                    }

                </div>

                <video className="Results-result-video" playsInline loop autoPlay muted>
                    <source src={ win ? videoWin : videoLose} type="video/mp4" />
                </video>
            </article>
        );
    }
}

export default SolveTheCase;
