import {Container} from 'unstated';
import history from './history';
import { CHARS } from './characters';
import axios from 'axios';
import * as ReactGA from './GA';

const SERVER_BASE = process.env.REACT_APP_SERVER;

export default class AppContainer extends Container {
    state = {
        char: 'all',
        playing: false,
        timeStatuses: {
            sofia: {
                normal: '0',
                voice: '',
                totalDuration: ''
            },
            rodolfo: {
                normal: '0',
                voice: '',
                totalDuration: ''
            },
            masako: {
                normal: '0',
                voice: '',
                totalDuration: ''
            },
            ettore: {
                normal: '0',
                voice: '',
                totalDuration: ''
            },
            franco: {
                normal: '0',
                voice: '',
                totalDuration: ''
            },
            olivia: {
                normal: '0',
                voice: '',
                totalDuration: ''
            }
        },
        videoJsOptions: {
            autoplay: true,
            controls: true,
            textTrackSettings: false,
            html5: {
                nativeTextTracks: false,
                hls: {
                  overrideNative: true
                }
            },
            sources: [{
              src: '',
              type: 'application/x-mpegURL'
            }],
            poster: ''
        },
        notes: {
            status: false,
            value: ''
        },
        accusation: {
            guilty: '',
            sended: false,
            total: 0,
            list: [{
                Sofia: 0,
                Rodolfo: 0,
                Masako: 0,
                Ettore: 0,
                Olivia: 0,
                Franco: 0
            }]
        },
        loading: false,
        analyticsTimes: {
            ten: false,
            quarter: false,
            half: false,
            threeQuarters: false
        }
    };

    updateChars = (component) => {
        if(component === 'all' && this.state.char !== 'all') {
            this.setState({
                char: 'all',
                playing: false,
            });
        } else {
            if(this.state.char !== component) {
                this.setState({
                    char: component,
                    playing: true,
                });

                let currentSrc = CHARS[component.toLocaleLowerCase()].src;
                let currentPoster = CHARS[component.toLocaleLowerCase()].poster;
                let videoJsOptions = {...this.state.videoJsOptions};

                videoJsOptions['sources'][0].src = currentSrc;
                videoJsOptions['poster'] = currentPoster;

                this.setState({
                    videoJsOptions
                });
            }
        }
    }

    // player
    handlePlaying = (name) => {
        this.updateChars(name.toLocaleLowerCase());
        history.push(`/indagati/${name}`);

        // reset analytics times
        let analyticsTimes = {...this.state.analyticsTimes}
        analyticsTimes.ten = false;
        analyticsTimes.quarter = false;
        analyticsTimes.half = false;
        analyticsTimes.threeQuarters = false;

        this.setState({
            char: name,
            playing: true,
            analyticsTimes
        }, () => {
            ReactGA.pageview(`/indagati/${name}`);
        });
    }

    closePlayer = () => {
        this.setState({
            playing: false
        }, () => {
            if(this.state.char !== 'all') {
                ReactGA.event({
                    category: `Video ${this.state.char}`,
                    action: `Close ${this.state.char}`,
                });
                ReactGA.pageview(`/indagati`);
            }
        });
    }

    updateTimeStatus = (timestamp, duration) => {
        let id = this.state.char.toLocaleLowerCase();
        let timeStatuses = {...this.state.timeStatuses};
        
        let elapsed = Math.round(timestamp);
        let totalDuration = Math.round(duration);
        let percent = Math.round(elapsed / totalDuration * 100);

        let analyticsTimes = {...this.state.analyticsTimes}

        if (!analyticsTimes.ten && percent > 10) {
            analyticsTimes.ten = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: `Video ${this.state.char}`,
                    action: 'Complete at 10%',
                });
            });
        }
        if (!analyticsTimes.quarter && percent > 25) {
            analyticsTimes.quarter = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: `Video ${this.state.char}`,
                    action: 'Complete at 25%',
                });
            });
        }
        if (!analyticsTimes.half && percent > 50) {
            analyticsTimes.half = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: `Video ${this.state.char}`,
                    action: 'Complete at 50%',
                });
            });
        }
        if (!analyticsTimes.threeQuarters && percent > 75) {
            analyticsTimes.threeQuarters = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: `Video ${this.state.char}`,
                    action: 'Complete at 75%',
                });
            });
        }

        if(timeStatuses[id].normal !== timestamp) {
            timeStatuses[id].normal = timestamp;

            this.setState({
                timeStatuses,
            });
        }
    }

    checkDuration = (duration) => {
        let id = this.state.char.toLocaleLowerCase();
        let timeStatuses = {...this.state.timeStatuses};

        timeStatuses[id].totalDuration = duration;

        this.setState({
            timeStatuses,
        });
    }

    onEnded = () => {
        ReactGA.event({
            category: `Video ${this.state.char}`,
            action: `Ended ${this.state.char}`,
        });
    }
    // player

    // notes
    toggleNotes = () => {
        let noteStatus = this.state.notes.status;
        let notes = {...this.state.notes};

        notes['status'] = !noteStatus;

        this.setState({
            notes
        });
    }

    handleChangeNotes = (ev) => {
        let noteValue = ev.target.value;
        let notes = {...this.state.notes};

        notes['value'] = noteValue;

        this.setState({
            notes
        });
    }
    // notes

    // accusation
    handleSelectGuilty = (guilty) => {
        let accusation = {...this.state.accusation};

        accusation['guilty'] = guilty;

        this.setState({
            accusation
        });
    }

    setSended = () => {
        let accusation = {...this.state.accusation};
        accusation['sended'] = true;
        
        this.setState({
            accusation
        });
    }

    getAccusations = (guilty) => {
        let accusation = {...this.state.accusation};
        accusation['list'][0] = {
            Sofia: 0,
            Rodolfo: 0,
            Masako: 0,
            Ettore: 0,
            Olivia: 0,
            Franco: 0
        };

        if(guilty) {
            accusation['guilty'] = guilty;
        }

        this.setState({
            loading: true
        });

        // get all accusations and save them in state
        axios.get(`${SERVER_BASE}/api/feedback`)
        .then((response) => {

            accusation['sended'] = true;
            accusation['total'] = Object.keys(response.data.data).length;

            Object.keys(response.data.data).map(index => (
                accusation['list'][0][response.data.data[index].value]++
            ));

            this.setState({
                accusation,
                loading: false
            });
        })
        .catch((error) => {
            // console.log(error);
        });
    }

    accuse = () => {
        let guilty = this.state.accusation.guilty;
        if(guilty !== '') {

            this.setState({
                loading: true
            });

            // save the accusation
            axios.post(`${SERVER_BASE}/api/feedback`, {
                value: guilty
            })
            .then((response) => {

                this.getAccusations();

                let accusation = {...this.state.accusation};
                accusation['sended'] = true;
                localStorage.setItem('n_accuse_sended', 'true');
                localStorage.setItem('n_accuse_guilty', this.state.accusation.guilty);

                let win = this.state.accusation.guilty === 'Olivia' ? 'Win' : 'Lose';

                this.setState({
                    accusation
                }, () => {
                    ReactGA.event({
                        category: `Game actions`,
                        action: win,
                    });
                });

                // console.log(response);
            })
            .catch((error) => {
                // console.log(error);
                alert('Si è verificato un errore, riprova tra poco!');
            });
            
        } else {
            alert('Scegli un colpevole');
            // console.log('scegli il colpevole');
        }
    }

    emptyAccuse = () => {
        let guilty = '';

        let accusation = {...this.state.accusation};
        accusation['sended'] = false;
        accusation['guilty'] = guilty;

        localStorage.removeItem('n_accuse_sended');
        localStorage.removeItem('n_accuse_guilty');

        this.setState({
            accusation,
        }, () => {
            ReactGA.event({
                category: `Game actions`,
                action: `Restart`,
            });
        });
        
    }
    // accusation
}