import React from 'react';
import videojs from 'video.js'
import 'videojs-landscape-fullscreen';
import 'videojs-vr';
import * as ReactGA from '../../GA';

import './VideoPlayer.scss';
import './VideoPlayer360.scss';
import './VideoPlayer-skin.scss';
import '../../../node_modules/video.js/dist/video-js.min.css';

export default class VideoPlayer360 extends React.Component {

  state = {
    analyticsTimes: {
      ten: false,
      quarter: false,
      half: false,
      threeQuarters: false
    }
  }

  componentDidMount() {
    // instantiate Video.js
    var player360 = videojs(this.videoNode360, this.props, function onPlayerReady() {
      // console.log('onPlayerReady', this);
    });

    player360.vr({
        projection: '360',
    });
    
    // callback on ended
    player360.on("ended", () => {
      this.props.onEnd();
    });

    // ontouch from mobile
    player360.on("touchstart", (e) => {
      player360.userActive(true);
    });

    // track event on pause
    player360.on('pause', () => {
      ReactGA.event({
        category: 'Video 360',
        action: 'Pause on 360',
      });
    });

    // track event on play
    player360.on('play', () => {
      ReactGA.event({
        category: 'Video 360',
        action: 'Play on 360',
      });
    });

    // track event on timeupdate
    player360.on('timeupdate', () => {
      let elapsed = Math.round(player360.currentTime());
      let totalDuration = Math.round(player360.duration());
      let percent = Math.round(elapsed / totalDuration * 100);

      let analyticsTimes = {...this.state.analyticsTimes}

      if (!analyticsTimes.ten && percent > 10) {
        analyticsTimes.ten = true;
        this.setState({
            analyticsTimes
        }, () => {
            ReactGA.event({
                category: 'Video 360',
                action: 'Complete at 10%',
            });
        });
      }
      if (!analyticsTimes.quarter && percent > 25) {
        analyticsTimes.quarter = true;
        this.setState({
            analyticsTimes
        }, () => {
            ReactGA.event({
                category: 'Video 360',
                action: 'Complete at 25%',
            });
        });
      }
      if (!analyticsTimes.half && percent > 50) {
        analyticsTimes.half = true;
        this.setState({
            analyticsTimes
        }, () => {
            ReactGA.event({
                category: 'Video 360',
                action: 'Complete at 50%',
            });
        });
      }
      if (!analyticsTimes.threeQuarters && percent > 75) {
        analyticsTimes.threeQuarters = true;
        this.setState({
            analyticsTimes
        }, () => {
            ReactGA.event({
                category: 'Video 360',
                action: 'Complete at 75%',
            });
        });
      }
    });

    // add custom button
    var VjsButton = videojs.getComponent("Button");
    var customButton = videojs.extend(VjsButton, {
           constructor: function(player, options){
            VjsButton.call(this, player, options);
           },
           handleClick: function() {
            //do something on click for example
           },
           buildCSSClass: function() {
            return "vjs-vr-icon vjs-control vjs-button";
           }
        });
    videojs.registerComponent("customButton", customButton);
    player360.addChild("customButton", {});

  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player360) {
      this.player360.dispose()
    }
  }

  render() {
    return (
      <div className="VideoPlayer VideoPlayer360">
        {
          this.props.episode &&
          <div className="VideoPlayer-title">Stai guardando: {this.props.episode}</div> 
        }
        <div className="VideoPlayer-player-container">
          <video ref={ node => this.videoNode360 = node } className="video-js vjs-ziqq vjs-ziqq-vr vjs-big-play-centered" playsInline crossOrigin="anonymous"></video>
        </div>
      </div>
    )
  }
}