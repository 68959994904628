import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import { ROUTES } from '../routes';
import Logo from './Logo/Logo';
import VideoPlayer360 from './VideoPlayer/VideoPlayer360';
import AnimatedBackgrounds from './AnimatedBackground/AnimatedBackgrounds';
import * as ReactGA from '../GA';

import './Solution.scss';

const CDN_BASE = process.env.REACT_APP_CDN;

const videoJsOptions = {
    autoplay: true,
    controls: true,
    sources: [{
      src: `${CDN_BASE}/video/360.mp4`,
      type: 'video/mp4'
    }]
}

class Solution extends Component {

    state = {
        ended: false
    }

    componentDidMount() {
        ReactGA.pageview(`/soluzione`);
    }

    onEnded = () => {
        this.setState({
            ended: true
        }, () => {
            ReactGA.event({
                category: 'Video 360',
                action: 'Ended 360',
            });
        })
    }
  
    render() {

        const { ended } = this.state;

        if (ended === true) {
            return <Redirect to={ROUTES.CREDITS} />
        }

        return (
            <article className="Solution">
                <Logo to={ROUTES.INVESTIGATED} />
                <div className="Page">
                    <div className="Solution-video">
                        <VideoPlayer360
                            { ...videoJsOptions }
                            onEnd={this.onEnded}/>
                    </div>
                </div>
                <Link to={ROUTES.INVESTIGATED}>Indietro</Link>
                <AnimatedBackgrounds />
            </article>
        );
    }
}

export default Solution;
