import React, { Component } from 'react';
import Slider from "react-slick";

import './Carousel.scss';

class Carousel extends Component {

    render() {
        const {
            items,
            AppStore
        } = this.props;

        var settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '0',
            swipeToSlide: true,
            focusOnSelect: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                }
            ]
        };

        const carouselItems = Object.keys(items).map((item, index) => (
            <div
                className="Carousel-item"
                key={items[item].name}
                onClick={(char) => AppStore.handlePlaying(items[item].name)}
                // to={'/watch/'+items[item].name}
                >
                <h3 className="Carousel-item-name">{items[item].name}</h3>
                <div className="Carousel-item-img" style={{backgroundImage: `url(${items[item].img})`}}>
                    <img className="hidden" src={items[item].img} alt={items[item].name} />
                </div>
                <div className="Carousel-item-state">
                    <div className="audio-state">
                        <div
                            className="state-placeholder"
                            style={{width: `${AppStore.state.timeStatuses[items[item].name.toLocaleLowerCase()].normal * 100 / AppStore.state.timeStatuses[items[item].name.toLocaleLowerCase()].totalDuration}%`}}></div>
                    </div>
                    {/* <div className="audio-state audio-state--voice">
                        <div className="state-placeholde"></div>
                    </div> */}
                </div>
                <div className="Carousel-item-details">
                    <div className="Carousel-item-detail Carousel-item-detail--role">{items[item].details.role}</div>
                    <div className="Carousel-item-detail">{items[item].details.age} anni</div>
                    <div className="Carousel-item-detail">{' | '}</div>
                    <div className="Carousel-item-detail">{items[item].details.height} m</div>
                </div>
            </div>
        ));

        return (
            <Slider {...settings}>
                {carouselItems}
            </Slider>
        );
    }
}
export default Carousel;