import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './Logo.scss';
import ZiqquratLogo from '../../assets/ziqqurat.svg';

class Logo extends Component {
  
    render() {
        return (
            <div className="Logo">
                { this.props.to ?
                    <Link to={this.props.to}>
                        <img src={ZiqquratLogo} alt="Il caso Ziqqurat" width="300" height="116" />
                    </Link>
                :
                    <Link to="#">
                        <img src={ZiqquratLogo} alt="Il caso Ziqqurat" width="300" height="116" />
                    </Link>
                }
            </div>
        );
    }
}

export default Logo;