import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import { ROUTES } from '../routes';
import Logo from './Logo/Logo';
import CarouselSolve from './CarouselSolve/CarouselSolve';
import { CHARS } from '../characters';
import AccusedItem from './AccusedItem/AccusedItem';
import Loader from './Loader/Loader';
import Sharing from './Sharing/Sharing';
import * as ReactGA from '../GA';

import './SolveTheCase.scss';

const BASE_URL = process.env.REACT_APP_BASE_URL;

class SolveTheCase extends Component {

    state = {
        sended: false,
        guilty: ''
    }

    componentDidMount() {

        // check reference to know if the user as already send his accusation
        const accuseSended = localStorage.getItem('n_accuse_sended');
        const accuseGuilty = localStorage.getItem('n_accuse_guilty');

        if(accuseSended !== null) {

            this.props.AppStore.getAccusations(accuseGuilty);

            this.setState({
                sended: true
            });
        } else {
            ReactGA.pageview(`/risolvi`);
        }
    }
  
    render() {

        const { AppStore } = this.props;

        const makeAccusationTpl = (
            <Fragment>
                <h1 className="SolveTheCase-title">Chi ha ucciso Bruno Simonpietro?</h1>
                <div className="Investigated-carousel SolveTheCase-carousel">
                    <CarouselSolve
                        items={CHARS}
                        select={AppStore.handleSelectGuilty}
                        selected={AppStore.state.accusation.guilty} />
                </div>
                <div className="SolveTheCase-buttons">
                    <Link to={ROUTES.INVESTIGATED} className="SolveTheCase-back">
                        <span>Indietro</span>
                    </Link>
                    <button className="SolveTheCase-accuse" onClick={AppStore.accuse}>
                        Accusa {AppStore.state.accusation.guilty}
                    </button>
                </div>
            </Fragment>
        );

        const accusationsTpl = Object.keys(AppStore.state.accusation.list[0]).map((accusation, index) => {
            return (
                <AccusedItem
                    key={index}
                    name={accusation}
                    number={AppStore.state.accusation.list[0][accusation]}
                    total={AppStore.state.loading ? 0 : AppStore.state.accusation.total} />
            );  
        });

        const statisticTpl = (
            <Fragment>
                <h1 className="SolveTheCase-title SolveTheCase-statistics-title">Hai accusato {AppStore.state.accusation.guilty}</h1>
                <div className="SolveTheCase-statistics">
                    { accusationsTpl }
                </div>
                <div className="SolveTheCase-buttons">
                    <Link to={ROUTES.INVESTIGATED} className="SolveTheCase-back">
                        <span>Indietro</span>
                    </Link>
                    <Link to={ROUTES.SOLUTION} className="SolveTheCase-accuse">
                        Guarda la soluzione
                    </Link>
                    <div className="SolveTheCase-share">
                        <Sharing
                            sharingTitle="Condividi la tua accusa"
                            small={true}
                            url={`${BASE_URL}/${AppStore.state.accusation.guilty.toLocaleLowerCase()}.html`}
                            title={`Secondo me il colpevole de Il Caso Ziqqurat è ${AppStore.state.accusation.guilty}`} />
                    </div>
                </div>
            </Fragment>
        );

        return (
            <article className={`SolveTheCase ${!AppStore.state.loading && (AppStore.state.accusation.sended || this.state.sended) ? 'SolveTheCaseStatistics' : ''}`}>
                <Logo to={ROUTES.INVESTIGATED} />
                <div className={`Page ${!AppStore.state.loading && (AppStore.state.accusation.sended || this.state.sended) ? 'Page-results' : ''}`}>

                    {/* is loading */}
                    { AppStore.state.loading &&
                        <Loader />
                    }

                    {/* is not loading and accusations sent */}
                    {
                        !AppStore.state.loading && (AppStore.state.accusation.sended || this.state.sended) &&
                        <Redirect to={ROUTES.RESULTS} />
                    }

                    {/* is not loading and accusations not sent */}
                    {
                        !AppStore.state.loading && !AppStore.state.accusation.sended && !this.state.sended &&
                        makeAccusationTpl
                    }

                </div>
            </article>
        );
    }
}

export default SolveTheCase;
