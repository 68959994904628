import React, { Component } from 'react';
import Slider from "react-slick";

import './CarouselSolve.scss';

class CarouselSolve extends Component {

    render() {
        const {
            items,
            select,
            selected
        } = this.props;

        var settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            centerPadding: '0',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px',
                  }
                }
            ]
        };

        const carouselItems = Object.keys(items).map((item, index) => (
            <div
                className={`Carousel-item CarouselSolve-item ${selected === items[item].name ? 'selected' : ''}`}
                key={items[item].name}
                onClick={(guilty) => select(items[item].name)}>
                    <div className="Carousel-item-img" style={{backgroundImage: `url(${items[item].picture})`}}>
                        <img className="hidden" src={items[item].picture} alt={items[item].name} />
                    </div>
                    <h3 className="Carousel-item-name">{items[item].name}</h3>
            </div>
        ));

        return (
            <Slider {...settings}>
                {carouselItems}
            </Slider>
        );
    }
}
export default CarouselSolve;