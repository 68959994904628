import React, { Component } from 'react';

import './AnimatedBackgrounds.scss';
import bg1 from '../../assets/bg-3.png';
import bg2 from '../../assets/bg-2.png';
import bg3 from '../../assets/bg-4.png';
import bg1desk from '../../assets/bg-3-desk.png';
import bg2desk from '../../assets/bg-2-desk.png';
import bg3desk from '../../assets/bg-4-desk.png';

const BGS = [bg1, bg2, bg3];
const BGSdesk = [bg1desk, bg2desk, bg3desk];

let interval;

class AnimatedBackgrounds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        let current = this.state.active;
        interval = setInterval(() => {
            if(current < BGS.length - 1) {
                current += 1;
            } else {
                current = 0;
            }
            this.setState({
                active: current
            });
        }, 11000);

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        clearInterval(interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    }
  
    render() {

        const { active, width } = this.state;

        const bgsTpl = BGS.map((bg, index) => {
            return (
                <img
                    key={index}
                    className={`bg-img bg-img-${index} ${index === active ? 'active' : ''}`}
                    src={bg}
                    alt="" />
            )
        });
        const bgsDeskTpl = BGSdesk.map((bg, index) => {
            return (
                <img
                    key={index}
                    className={`bg-img bg-img-${index} ${index === active ? 'active' : ''}`}
                    src={bg}
                    alt="" />
            )
        });

        return (
            <div className="AnimatedBackgrounds">
                <div className="AnimatedBackgrounds-content">
                    { width <= 1025
                    ?
                        bgsTpl
                    :
                        bgsDeskTpl
                    }
                </div>
            </div>
        );
    }
}

export default AnimatedBackgrounds;