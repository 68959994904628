import React from 'react';
import {Switch, Route } from 'react-router-dom';
import { Subscribe } from 'unstated';
import AppContainer from './AppContainer';

import Home from './components/Home';
import Tool from './components/Tool';
import Intro from './components/Intro';
import Investigated from './components/Investigated';
import SolveTheCase from './components/SolveTheCase';
import Solution from './components/Solution';
import Results from './components/Results';
import Credits from './components/Credits/Credits';

export const ROUTES = {
    HOME: '/',
    INTRO: '/intro',
    TOOL: '/strumento',
    INVESTIGATED: '/indagati/all',
    SOLVE: '/risolvi',
    SOLUTION: '/soluzione',
    RESULTS: '/risultato',
    CREDITS: '/credits',
};

export default function routes(props) {
    return (
        <Subscribe to={[AppContainer]}>
            {(AppStore) =>
                <Switch>
                    <Route exact path={ROUTES.HOME} component={Home} />
                    <Route exact path={ROUTES.TOOL} component={Tool} />
                    <Route exact path={ROUTES.INTRO} component={Intro} />
                    <Route
                        path="/indagati/:type"
                        render={(props) => <Investigated {...props} AppStore={AppStore} />}
                        />
                    <Route
                        path={ROUTES.SOLVE}
                        render={(props) => <SolveTheCase {...props} AppStore={AppStore} />}
                        />
                    <Route
                        path={ROUTES.RESULTS}
                        render={(props) => <Results {...props} AppStore={AppStore} />}
                        />
                    <Route exact path={ROUTES.SOLUTION} component={Solution} />
                    <Route exact path={ROUTES.CREDITS} component={Credits} />
                </Switch>
            }
        </Subscribe>
    );
}
