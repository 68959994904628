import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import { ROUTES } from '../routes';
import Logo from './Logo/Logo';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import AnimatedBackgrounds from './AnimatedBackground/AnimatedBackgrounds';
import * as ReactGA from '../GA';

import './Tool.scss';

const CDN_BASE = process.env.REACT_APP_CDN;

const videoJsOptions = {
    autoplay: true,
    controls: true,
    textTrackSettings: false,
    html5: {
        nativeTextTracks: false,
        hls: {
            overrideNative: true,
        },
    },
    sources: [{
      src: `${CDN_BASE}/video/playlist-strumento.m3u8`,
      type: 'application/x-mpegURL'
    }]
}

class Tool extends Component {

    state = {
        ended: false,
        alreadySeen: false,
        analyticsTimes: {
            ten: false,
            quarter: false,
            half: false,
            threeQuarters: false
        }
    };

    componentDidMount() {
        ReactGA.pageview(`/strumento`);
        const toolEnded = localStorage.getItem('tool_ended');

        if (toolEnded !== null) {
            this.setState({
                alreadySeen: true
            });
        } else {
            localStorage.setItem('tool_ended', 'true');
        }
    }

    toolIsEnded = () => {
        this.setState({
            ended: true
        }, () => {
            ReactGA.event({
                category: 'Video Strumento',
                action: 'Ended Strumento',
            });
        })
    }

    updateTimeStatus = (timestamp, duration) => {
        let elapsed = Math.round(timestamp);
        let totalDuration = Math.round(duration);
        let percent = Math.round(elapsed / totalDuration * 100);

        let analyticsTimes = {...this.state.analyticsTimes}

        if (!analyticsTimes.ten && percent > 10) {
            analyticsTimes.ten = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: 'Video Strumento',
                    action: 'Complete at 10%',
                });
            });
        }
        if (!analyticsTimes.quarter && percent > 25) {
            analyticsTimes.quarter = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: 'Video Strumento',
                    action: 'Complete at 25%',
                });
            });
        }
        if (!analyticsTimes.half && percent > 50) {
            analyticsTimes.half = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: 'Video Strumento',
                    action: 'Complete at 50%',
                });
            });
        }
        if (!analyticsTimes.threeQuarters && percent > 75) {
            analyticsTimes.threeQuarters = true;
            this.setState({
                analyticsTimes
            }, () => {
                ReactGA.event({
                    category: 'Video Strumento',
                    action: 'Complete at 75%',
                });
            });
        }
    }
  
    render() {
        
        const { ended, alreadySeen } = this.state;

        if (ended === true) {
            return <Redirect to={ROUTES.INTRO} />
        }

        return (
            <article className="Tool">
                <Logo to={ROUTES.INTRO} />
                <div className="Page">
                    <div className="Tool-video">
                        <VideoPlayer
                            { ...videoJsOptions }
                            onUpdate={this.updateTimeStatus}
                            onEnd={this.toolIsEnded} />
                    </div>
                </div>
                { alreadySeen ?
                    <Link to={ROUTES.INTRO} className="btn">Torna all'indagine</Link>
                    :
                    <Link to={ROUTES.INTRO} className="btn">Inizia l'indagine</Link>
                }
                <AnimatedBackgrounds />
            </article>
        );
    }
}

export default Tool;