import React, { Component } from 'react';

import './Notes.scss';

class Notes extends Component {
    render() {
        const { noteValue, handleChangeNotes, toggleNotes } = this.props;
        return (
            <div className="Notes">
                <div className="Notes-content">
                    <button onClick={toggleNotes} className="Notes-close">Chiudi</button>
                    <textarea onChange={handleChangeNotes} defaultValue={noteValue}></textarea>
                </div>
                <div className="Notes-overlay" onClick={toggleNotes} />
            </div>
        );
    }
}

export default Notes;