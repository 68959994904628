import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes';
import Logo from '../Logo/Logo';
import AnimatedBackgrounds from '../AnimatedBackground/AnimatedBackgrounds';
import Artchivio from '../../assets/logos/artchivio.svg';
import Cloud9 from '../../assets/logos/cloud9.svg';
import Dbw from '../../assets/logos/dbw.svg';
import Inhouse from '../../assets/logos/inhouse.svg';
import Frita from '../../assets/logos/frita.png';
import Teatro from '../../assets/logos/teatrodelladodicesima.png';
import Siae from '../../assets/logos/siae.png';
import * as ReactGA from '../../GA';

import './Credits.scss';


class Credits extends Component {

    componentDidMount() {
        ReactGA.pageview(`/credits`);
    }

    render() {
        return(
            <article className="Credits">
                <Logo to={ROUTES.INVESTIGATED} />
                <Link to={ROUTES.INVESTIGATED} className="Credits-back">Indietro</Link>
                <div className="Page">
                    <div className="Scroller">
                        <div className="table">
                            <div className="table-line">
                                <div className="table-col">
                                    <p>Prodotto da</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Amedeo Bacigalupo</strong><br/>
                                    <strong>Dioniso Chelsea</strong><br/>
                                    <strong>Marcello Di Trocchio</strong><br/>
                                    <strong>Andrea Schiavoni</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Regia di</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fulvio Risuleo</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Soggetto di</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fulvio Risuleo</strong><br/>
                                    <strong>Jean Paul Sneider</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Sceneggiatura di</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fulvio Risuleo</strong><br/>
                                    <strong>Jean Paul Sneider</strong><br/>
                                    <strong>Eliseo Acanfora</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />
                            
                            <div className="table-line">
                                <div className="table-col">
                                    <p>L’investigatore</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fabio Visca</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Il tecnico Ettore</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Giovanni Esposito</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Il truccatore Franco</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Danilo Bertazzi</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>La presentatrice Sofia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Sonia Ceriola</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>L’attrezzista Olivia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Federica Sabatini</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>L’esploratore John</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Antonio Bannò</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>L’origamista Masako</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Sonia Zhou Fenxia</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Il marionettista Bruno</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Tonino Risuleo</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Direttore della fotografia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Guido Mazzoni</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Scenografia e Costumi</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Stefano Ciammitti</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Direttore artistico</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Frita</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Fonico di presa diretta</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fabio Fortunati</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Makeup artist and Hair stylist</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Giorgia Melillo</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Aiuto regia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Lorenzo Puntoni</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Script supervisor</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Denise Pintaldi</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Delegata di produzione per Cloud9 Film</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Sabina Tranquilli</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Montaggio video</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Ilenia Zincone</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Montaggio del suono</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Alessandro Bonfanti</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Mix</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Mirko Perri</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Audio post-production manager</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Victor Rosalini Spacek</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Musiche</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Virginia Quaranta</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>I assistente e II operatore</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Juri Fantigrossi</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Aiuto operatore e Data manager</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Lorenzo Sun</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Video assist</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fabio Frangione</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Capo elettricista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Stanislao Galasso</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Capo macchinista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Andrea Lorusso</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Elettricista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Andrea Rinaldi</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Operatore 360</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Giacomo Bevilacqua</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Microfonista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Michele Priore</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Ass.te scenografia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Fiordiligi Focardi</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Attrezzista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Andrea Vettese</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Ass.te costumi</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Alessia Bonfini</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Aiuto costumi</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Chiara Marchi</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Assistente alla regia</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Giovanni Grandoni</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Foto</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Ermete Ricci</strong><br/><strong>Alessandro Signorini</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Food & Beverage</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Biker’s Bar</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Runner</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Jacopo Narici</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Rumorista</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Mauro Eusepi</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Color correction</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Carla Leoni per DBW Communication</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Animation colorist</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Valerio Immordino</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Trailer</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Ermete Ricci</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Creative coding</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Michele Maietta</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Communication strategy</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Elena Seno</strong></p>
                                </div>
                            </div>

                            <div className="table-line">
                                <div className="table-col">
                                    <p>Ufficio stampa</p>
                                </div>
                                <div className="table-col">
                                    <p><strong>Jacopo Bistacchia</strong></p>
                                </div>
                            </div>

                            <div className="table-line table-line-space" />

                            {/* loghi */}
                            <div className="Credits-logos">
                                <a
                                    href="https://artchivio.net/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Artchivio} alt="Artchivio" />
                                </a>
                                <a
                                    href="http://www.nuvolafilm.com/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Cloud9} alt="Cloud9" />
                                </a>
                                <a
                                    href="http://www.dbwcommunication.it/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Dbw} alt="DBW" />
                                </a>
                            </div>
                            <div className="Credits-logos">
                                <a
                                    href="https://www.inhousepost.it/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Inhouse} alt="INHOUSE" />
                                </a>
                                <a
                                    href="https://www.facebook.com/frita88/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Frita} alt="Frita" />
                                </a>
                                <a
                                    href="http://www.teatrodelladodicesima.it/"
                                    target="_blank" rel="noopener noreferrer">
                                    <img src={Teatro} alt="Teatro della Dodicesima" />
                                </a>
                            </div>
                            <div className="Credits-logos Credits-logos-spaced">
                                <img src={Siae} alt="Con il sostegno del MiBACT e di SIAE, nell'ambito dell'iniziativa Sillumina - Copia privata per i giovani, per la cultura" />
                            </div>

                            <h3>IL REGISTA RINGRAZIA</h3>
                            <p><strong>Francesca Romana Napoleoni, Andrea Sorini, Stefano Fornai</strong></p>

                            <div className="table-line table-line-space" />
                            
                            <h3>ARTCHIVIO RINGRAZIA</h3>
                            <p><strong>Francesco Carrelli Palombi, David Nicosia, Tommaso Cassinis, Barbara Sebastiani, Giovanni Risuleo, Diana Biaggini, Angelo Maggi, Holly Heuser, Marco Giannoni, Leonardo Cini, Nicola Scaldaferri, Via Paolo Caselli, Sergione, Buc il cane</strong></p>

                        </div>
                    </div>
                </div>
                <AnimatedBackgrounds />
            </article>
        );
    }
}

export default Credits;