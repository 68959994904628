import React, { Component } from 'react';
import { ROUTES } from '../routes';
import {Link} from 'react-router-dom';
import Logo from './Logo/Logo';
import AnimatedBackgrounds from './AnimatedBackground/AnimatedBackgrounds';
import { isMobile, isIOS } from "react-device-detect";
import * as ReactGA from '../GA';

import './Intro.scss';

class Intro extends Component {

    componentDidMount() {
        ReactGA.pageview(`/intro`);
    }

    render() {

        return (
            <article className="Intro">
                <Logo to={ROUTES.INVESTIGATED} />
                <div className="Page">
                    <p className="Intro-text">Per attivare la voce pensiero dell’investigatore durante gli episodi premi il tasto:</p>
                    <div className={`Intro-voice-icon ${isMobile && isIOS ? 'Intro-voice-icon-safari' : ''}`}></div>
                    { isMobile && isIOS &&
                        <p>e seleziona "ascolta pensiero"</p>
                    }
                </div>
                <Link to={ROUTES.INVESTIGATED} className="btn">Ok, ho capito</Link>
                <AnimatedBackgrounds />
            </article>
        );
    }
}

export default Intro;