import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

import './Sharing.scss';

class Sharing extends Component {

    render() {

        const { url, title, small, sharingTitle } = this.props;

        const iconSize = small ? 30 : 45;

        return (
            <div className={`Sharing ${small ? 'Sharing-small' : ''}`}>
                {sharingTitle &&
                    <h4 className="Sharing-title">{sharingTitle}</h4>
                }
                {isMobile &&
                    <WhatsappShareButton url={`${url}`} title={title}>
                        <WhatsappIcon size={iconSize} round={true} iconBgStyle={{fill: 'transparent'}} />
                    </WhatsappShareButton>
                }
                <FacebookShareButton url={`${url}`} quote={title} hashtag="#ilcasoziqqurat">
                    <FacebookIcon size={iconSize} round={true} iconBgStyle={{fill: 'transparent'}} />
                </FacebookShareButton>
                {!isMobile &&
                    <EmailShareButton url={`${url}`} subject={title}>
                        <EmailIcon size={iconSize} round={true} iconBgStyle={{fill: 'transparent'}} />
                    </EmailShareButton>
                }
            </div>
        );
    }
}

export default Sharing;