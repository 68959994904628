import sofiaImg from './assets/sofia-img.png';
import johnImg from './assets/john-img.png';
import masakoImg from './assets/masako-img.png';
import ettoreImg from './assets/ettore-img.png';
import oliviaImg from './assets/olivia-img.png';
import francoImg from './assets/franco-img.png';

import sofiaPicture from './assets/sofia-picture.png';
import johnPicture from './assets/jonh-picture.png';
import masakoPicture from './assets/masako-picture.png';
import ettorePicture from './assets/ettore-picture.png';
import oliviaPicture from './assets/olivia-picture.png';
import francoPicture from './assets/franco-picture.png';

import sofiaPoster from './assets/ziqqurat-poster.jpg';
import johnPoster from './assets/ziqqurat-poster.jpg';
import masakoPoster from './assets/ziqqurat-poster.jpg';
import ettorePoster from './assets/ziqqurat-poster.jpg';
import oliviaPoster from './assets/ziqqurat-poster.jpg';
import francoPoster from './assets/ziqqurat-poster.jpg';

const CDN_BASE = process.env.REACT_APP_CDN;

export const CHARS = {
    sofia: {
        name: 'Sofia',
        img: sofiaImg,
        picture: sofiaPicture,
        poster: sofiaPoster,
        details: {
            role: 'La presentatrice',
            age: '49',
            height: '1.65'
        },
        src: `${CDN_BASE}/video/playlist-sofia.m3u8`,
    },
    rodolfo: {
        name: 'Rodolfo',
        img: johnImg,
        picture: johnPicture,
        poster: johnPoster,
        details: {
            role: 'L\'esploratore John',
            age: '27',
            height: '1.81'
        },
        src: `${CDN_BASE}/video/playlist-john.m3u8`,
    },
    masako: {
        name: 'Masako',
        img: masakoImg,
        picture: masakoPicture,
        poster: masakoPoster,
        details: {
            role: 'L\'origamista',
            age: '52',
            height: '1.71'
        },
        src: `${CDN_BASE}/video/playlist-masako.m3u8`,
    },
    ettore: {
        name: 'Ettore',
        img: ettoreImg,
        picture: ettorePicture,
        poster: ettorePoster,
        details: {
            role: 'Il tecnico',
            age: '51',
            height: '1.72'
        },
        src: `${CDN_BASE}/video/playlist-ettore.m3u8`,
    },
    olivia: {
        name: 'Olivia',
        img: oliviaImg,
        picture: oliviaPicture,
        poster: oliviaPoster,
        details: {
            role: 'L\'attrezzista',
            age: '24',
            height: '1.74'
        },
        src: `${CDN_BASE}/video/playlist-olivia.m3u8`,
    },
    franco: {
        name: 'Franco',
        img: francoImg,
        picture: francoPicture,
        poster: francoPoster,
        details: {
            role: 'Il truccatore',
            age: '47',
            height: '1.75'
        },
        src: `${CDN_BASE}/video/playlist-franco.m3u8`,
    },
};