import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../routes';
import Logo from './Logo/Logo';
import * as ReactGA from '../GA';

import './Home.scss';

class Home extends Component {

    componentDidMount() {
        ReactGA.pageview(`/`);
    }
  
    render() {
        return (
            <article className="Home">
                <Logo />
                <div className="Page">
                    <p className="Home-text">Una trasmissione per bambini. Un morto. Sei&nbsp;sospettati.<br/><br/><strong>Riuscirai a risolvere il rompicapo?</strong></p>
                    <Link to={ROUTES.TOOL} className="btn">Guarda la prova</Link>
                </div>
            </article>
        );
    }
}

export default Home;