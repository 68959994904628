import React, { Component } from 'react';

import './Loader.scss';

class Loader extends Component {
  
    render() {
        return (
            <div className="Loader"><span>Caricamento</span></div>
        );
    }
}

export default Loader;