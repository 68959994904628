import React from 'react';
import videojs from 'video.js'
import 'videojs-landscape-fullscreen';
import * as ReactGA from '../../GA';

import './VideoPlayer.scss';
import './VideoPlayer-skin.scss';
// import '../../../node_modules/video.js/dist/video-js.min.css';

export default class VideoPlayer extends React.Component {

  componentDidMount() {
    // instantiate Video.js
    var newPlayer = videojs(this.videoNode, this.props);

    newPlayer.on("ready", () => {
      // console.log('onPlayerReady', newPlayer);
      // check duration of current source

      if(this.props.checkDuration) {
        
        let duration;

        this.calcDuration = setTimeout(() => {
          duration = newPlayer.duration()
        }, 1000);

        this.setDuration = setTimeout(() => {
          // console.log(duration);
          this.props.checkDuration(duration);
        }, 1200);
      }

      // if we have a timeStamp start from that
      if(this.props.timeStamp) {
        newPlayer.currentTime(this.props.timeStamp)
      }
    });

    newPlayer.landscapeFullscreen({
        fullscreen: {
          enterOnRotate: true,
          alwaysInLandscapeMode: true,
          iOS: false
      },
    });

    // add custom button
    var VjsButton = videojs.getComponent("Button");
    var customButton = videojs.extend(VjsButton, {
           constructor: function(player, options){
            VjsButton.call(this, player, options);
                this.controlText("Indietro");
           },
           handleClick: function() {
            //do something on click for example
            newPlayer.exitFullscreen();
           },
           buildCSSClass: function() {
            return "vjs-exit-fullscreen vjs-control vjs-button";
           }
        });
    videojs.registerComponent("customButton", customButton);
    newPlayer.getChild("controlBar").addChild("customButton", {});
    
    // onended callback
    newPlayer.on("ended", () => {
      this.props.onEnd();
    });

    // onclick from desktop
    newPlayer.on("click", (e) => {
    });

    // ontouch from mobile
    newPlayer.on("touchstart", (e) => {
      // console.log(e.target.className);
      // play pause only if clicking not on buttons
      if(e.target.className === 'vjs-tech') {
        var isActive = newPlayer.userActive();
        // if controls are active play/pause
        if(isActive) {
          if (newPlayer.paused()) {
            newPlayer.play();
          }
          else {
            newPlayer.pause();
          }
        }
      }
    });

    if(this.props.onUpdate) {
      newPlayer.on('timeupdate', () => {
        // console.log(newPlayer.currentTime());
        if(this.props.timeStamp !== newPlayer.currentTime()) {
          this.props.onUpdate(newPlayer.currentTime(), newPlayer.duration());
        }
      });
    }

    newPlayer.on('pause', () => {
      let name = this.props.episode || 'Strumento';
      ReactGA.event({
        category: `Video ${name}`,
        action: `Pause on ${name}`,
      });
    });

    newPlayer.on('play', () => {
      let name = this.props.episode || 'Strumento';
      ReactGA.event({
        category: `Video ${name}`,
        action: `Play on ${name}`,
      });
    });

    // newPlayer.on('fullscreenchange', (e) => {
    //   console.log('screenchange');
    //   console.log(newPlayer.isFullscreen());
    //   setTimeout(() => {
    //     window.scrollTo(0,1);
    //   }, 1000);
    // })

    // debugging infos
    // newPlayer.on('timeupdate', () => {
    //   var hls = newPlayer.tech().hls;
    //   console.log(hls.stats);
    // });

  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.newPlayer) {
      this.newPlayer.dispose();
    }
    clearTimeout(this.calcDuration, this.setDuration);
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="VideoPlayer">
        {
          this.props.episode &&
          <div className="VideoPlayer-title">{this.props.episode}</div> 
        }
        <div className="VideoPlayer-player-container">
          <video ref={ node => this.videoNode = node } className="vjs-ziqq video-js vjs-big-play-centered"></video>
        </div>
      </div>
    )
  }
}