import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes';

import './InvestigatedNav.scss';


class InvestigatedNav extends Component {

    render() {
        const { toggleNotes, sended } = this.props;

        return(
            <div className="Investigated-nav">
                <button
                    onClick={toggleNotes}
                    className="Investigated-nav-notes">Appunti</button>
                <Link
                    to={ROUTES.TOOL}
                    className="Investigated-nav-tool">La prova</Link>
                { sended
                ?
                    <Link
                        to={ROUTES.SOLVE}
                        className="Investigated-nav-solve">Soluzione</Link>
                :
                    <Link
                        to={ROUTES.SOLVE}
                        className="Investigated-nav-solve">Risolvi</Link>
                }
            </div>
        );
    }
}

export default InvestigatedNav;