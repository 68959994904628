import React, { Component } from 'react';
import { ROUTES } from '../routes';
import Carousel from './Carousel/Carousel';
import Logo from './Logo/Logo';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import InvestigatedNav from './InvestigatedNav/InvestigatedNav';
import Notes from './Notes/Notes';
import { CHARS } from '../characters';
import AnimatedBackgrounds from './AnimatedBackground/AnimatedBackgrounds';
import CreditsLink from './Credits/CreditsLink';
import Sharing from './Sharing/Sharing';
import * as ReactGA from '../GA';

import './Investigated.scss';

const BASE_URL = process.env.REACT_APP_BASE_URL;

class Investigated extends Component {

    componentDidMount() {
        ReactGA.pageview(`/indagati`);

        this.props.AppStore.updateChars(this.props.match.params.type);

        // check reference to know if the user as already send his accusation
        const accuseSended = localStorage.getItem('n_accuse_sended');
        if(accuseSended !== null) {
            this.props.AppStore.setSended();
        }
    }

    componentWillUnmount() {
        this.props.AppStore.closePlayer();
    }
  
    render() {

        const { AppStore } = this.props; 

        return (
            <article className="Investigated">
                <Logo to={ROUTES.INVESTIGATED} />
                { AppStore.state.playing &&
                    <div className="Investigated-player">
                        <div className="Investigated-player-close" onClick={AppStore.closePlayer}>
                            <span>Chiudi</span>
                        </div>
                        <VideoPlayer
                            { ...AppStore.state.videoJsOptions }
                            onUpdate={AppStore.updateTimeStatus}
                            episode={AppStore.state.char}
                            checkDuration={AppStore.checkDuration}
                            onEnd={AppStore.onEnded}
                            timeStamp={AppStore.state.timeStatuses[AppStore.state.char.toLocaleLowerCase()].normal }
                            />
                        <div className="Investigated-player-overlay" onClick={AppStore.closePlayer}></div>
                        <div className="Investigated-player-share">
                            <Sharing
                                url={`${BASE_URL}/indagati/${AppStore.state.char}`}
                                title={`Guarda l'interrogatorio di ${AppStore.state.char} de Il Caso Ziqqurat`} />
                        </div>
                    </div>
                }
                
                <div className="Page">
                    <div className="Investigated-carousel">
                        <Carousel items={CHARS} AppStore={AppStore} />
                    </div>
                </div>

                { AppStore.state.notes.status &&
                    <Notes
                        handleChangeNotes={AppStore.handleChangeNotes}
                        noteValue={AppStore.state.notes.value}
                        toggleNotes={AppStore.toggleNotes} />
                }

                <InvestigatedNav toggleNotes={AppStore.toggleNotes} sended={AppStore.state.accusation.sended} />
                <CreditsLink />
                <AnimatedBackgrounds />
            </article>
        );
    }
}

export default Investigated;
