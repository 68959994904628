import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from '../../routes';

import './CreditsLink.scss';


class CreditsLink extends Component {

    render() {
        return(
            <Link className="CreditsLink" to={ROUTES.CREDITS}>
                <span>Credits</span>
            </Link>
        );
    }
}

export default CreditsLink;